/**
 * 营销活动管理-营销事件管理-编辑页
 * luxinwen
 * 2023-04-11
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="700" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="150">
        <div class="page-main-content-thead">基本信息</div>
        <FormItem label="事件类型" prop="eventType">
          <Select v-model="formData.eventType" @on-change="changeType" :disabled="isShow || isEdit">
            <Option v-for="(item, index) in eventTypeList" :key="'eventType' + index" :value="item.code">{{ item.desc }}</Option>
          </Select>
        </FormItem>
        <FormItem label="事件ID">
          <Input v-model="formData.eventId" disabled></Input>
        </FormItem>
        <FormItem label="事件名称" prop="eventName">
          <Input v-model.trim="formData.eventName" maxlength="50" :disabled="isShow"></Input>
        </FormItem>
        <template v-if="formData.triggerMode === 0">
          <FormItem label="事件有效期" prop="time">
            <DatePicker v-model="formData.time" ref="formDataTime" class="width-full" type="datetimerange" placement="bottom-end" :disabled="isShow" @on-change="changeTime"></DatePicker>
          </FormItem>
          <FormItem label="触达频率" prop="frequency">
            <Select v-model="formData.frequency" clearable :disabled="isShow || isDisabled">
              <Option v-for="(item, index) in frequencyList" :key="'frequency' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
        </template>
        <div class="page-main-content-thead">发放积分</div>
        <FormItem label="发放积分数" prop="points">
          <InputNumber v-model="formData.points" :precision="0" :step="1" :min="0" :max="99999" :disabled="isShow"></InputNumber>
        </FormItem>
        <template v-if="formData.points > 0">
          <!-- <FormItem label="发分总数限制" prop="isLimitPoint">
            <RadioGroup v-model="formData.isLimitPoint" @on-change="changeLimit">
              <Radio label="1" :disabled="isShow">无限制</Radio>
              <Radio label="2" :disabled="isShow">限制总数</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="发放积分上限" prop="pointsPool" v-if="formData.isLimitPoint === '2'">
            <InputNumber v-model="formData.pointsPool" :min="0" :max="9999999" :disabled="isShow"></InputNumber>
          </FormItem> -->
          <!-- <FormItem label="发分主体" prop="merchantCode">
            <Select v-model="formData.merchantCode" placeholder="请选择商户" filterable :disabled="isShow" @on-change="merchantChange">
              <Option v-for="item in merchantList" :key="'merchant' + item.merchantCode" :value="item.merchantCode">{{ item.merchantNameZh }}</Option>
            </Select>
          </FormItem>
          <FormItem prop="storeCode" :label-width="0">
            <Select v-model="formData.storeCode" placeholder="请选择店铺" filterable :disabled="isShow" @on-query-change="getStoreList">
              <Option v-for="item in storeList" :key="'store' + item.storeCode" :value="item.storeCode">{{ item.storeName }}</Option>
            </Select>
          </FormItem> -->
        </template>
        <!-- <div class="page-main-content-thead">
          发放优惠券
          <Button type="info" :disabled="isShow" @click="addCoupon">添加</Button>
        </div>
        <div v-for="(item, i) in formData.batchIdList" :key="'batchId' + i">
          <FormItem label="券批次ID" :prop="'batchIdList[' + i + ']'" :rules="formRule.batchIdList">
            <Input v-model="formData.batchIdList[i]" class="width-m" :disabled="isShow"></Input>
            <Button type="text" size="large" icon="ios-remove-circle" :disabled="isShow" @click="removeCoupon(i)"></Button>
          </FormItem>
        </div> -->
        <div class="page-main-content-thead">触达会员范围</div>
        <FormItem label="会员范围" prop="memberRange">
          <RadioGroup v-model="formData.memberRange" @on-change="changeMemberRange">
            <Radio v-for="(value, key) in memberRangeList" :key="'memberRange' + key" :label="Number(key)" :disabled="isShow || isDisabled">{{ value }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="会员等级" prop="memberLevelList" v-if="formData.memberRange === 0">
          <CheckboxGroup v-model="formData.memberLevelList">
            <Checkbox v-for="item in levelList" :key="item.levelId" :label="item.levelId" :disabled="isShow || isDisabled">{{ item.levelCode }}</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <div class="padding-b-10" v-if="formData.memberRange === 1">
          <div class="padding-b-10">
            <Button type="info" :disabled="isShow" @click="addMember">添加会员</Button>
            <Button type="info" :disabled="isShow" @click="removeMember">删除</Button>
          </div>
          <Table row-key="memberId" :columns="memberColumns" :data="memberList" border @on-selection-change="changeSelection"></Table>
        </div>
        <FormItem label="是否员工专享" prop="employeeExclusive">
          <RadioGroup v-model="formData.employeeExclusive">
            <Radio :label="0" :disabled="isShow || isDisabled">否</Radio>
            <Radio :label="1" :disabled="isShow || isDisabled">是</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
      <div class="drawer-foot" v-show="!isShow">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { EVENT_MEMBER_RANGE } from '@/util/enum';
  const formData = {
    batchIdList: [], // 券批次ID列表
    beginTime: '',  // 事件有效期开始
    endTime: '',  // 事件有效期结束
    employeeExclusive: null, // 是否员工专享，0:否;1:是
    eventId: '', // 事件ID
    eventName: '', // 事件名称
    eventType: null, // 事件类型，0:会员注册;1:实名认证;2:自定义事件
    frequency: null, // 触达频率，0:实时;1:每日;2:每周;3:每月
    memberIdList: [], // 会员信息列表
    memberLevelList: [], // 参与会员等级，v1:0;v2:1;v3:2;v4:3
    memberRange: null, // 会员范围，0:等级筛选;1:手工指定
    points: 0, // 发放积分数
    pointsPool: -1, // 发分总数限制，-1:无限制.不为-1的则为发放积分上限
    // merchantCode: '', // 发分商户
    // storeCode: '', // 发分店铺
    triggerMode: 0, // 触发方式 0 自动 1手动
    isLimitPoint: '', // 是否限制发分总数
    time: [] // 事件有效期
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          eventType: [
            {
              type: 'number',
              required: true,
              message: '请选择事件类型',
              trigger: 'change'
            }
          ],
          eventName: [
            {
              required: true,
              message: '请输入事件名称',
              trigger: 'blur'
            }
          ],
          time: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (value && value.length > 0 && value.every(item => item)) {
                  callback();
                } else {
                  callback(new Error('请选择事件有效期'));
                }
              }
            }
          ],
          frequency: [
            {
              type: 'number',
              required: true,
              message: '请选择触发频率',
              trigger: 'change'
            }
          ],
          isLimitPoint: [
            {
              required: true,
              message: '请选择发分总数限制',
              trigger: 'change'
            }
          ],
          pointsPool: [
            {
              type: 'integer',
              required: true,
              message: '请输入发放积分上限',
              trigger: 'blur'
            },
            {
              type: 'integer',
              trigger: 'blur',
              validator: (rule, value, callback) => {
                if (value <= this.formData.points) {
                  callback(new Error('发送积分上限应大于发放积分数'));
                } else {
                  callback();
                }
              }
            }
          ],
          // merchantCode: [
          //   {
          //     required: true,
          //     message: '请选择商户',
          //     trigger: 'change'
          //   }
          // ],
          // storeCode: [
          //   {
          //     required: true,
          //     message: '请选择店铺',
          //     trigger: 'change'
          //   }
          // ],
          // batchIdList: [
          //   {
          //     required: true,
          //     message: '请输入券批次ID',
          //     trigger: 'blur'
          //   }
          // ],
          memberRange: [
            {
              type: 'number',
              required: true,
              message: '请选择会员范围',
              trigger: 'change'
            }
          ],
          memberLevelList: [
            {
              type: 'array',
              required: true,
              message: '请选择会员等级',
              trigger: 'change'
            }
          ],
          employeeExclusive: [
            {
              type: 'number',
              required: true,
              message: '请选择是否员工专享',
              trigger: 'change'
            }
          ]
        },
        backupData: {},
        eventTypeList: [],
        frequencyList: [],
        memberRangeList: EVENT_MEMBER_RANGE,
        levelList: [],
        // merchantList: [],
        // storeList: [],
        memberColumns: [
          {
            type: 'selection',
            align: 'center',
            width: 60
          },
          {
            title: '会员编码',
            key: 'memberId'
          },
          {
            title: '会员手机号',
            key: 'mobile'
          },
          {
            title: '状态',
            key: 'status'
          },
          {
            title: '会员等级',
            key: 'memberLevel'
          },
          {
            title: '会员积分',
            key: 'availablePoints'
          }
        ],
        memberList: [],
        selectMember: []
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '事件';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isAdd() {
        return this.flag === 'add';
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      },
      isDisabled() {
        return [0, 1, 3, 4, 5].indexOf(this.formData.eventType) !== -1;
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        this.formData.time = [data.beginTime, data.endTime];
        this.formData.isLimitPoint = this.formData.pointsPool === -1 ? '1' : '2';
        this.formData.batchIdList = data.batchIdList || [];
        this.formData.memberLevelList = data.memberLevelList || [];
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.getTypeList();
        this.getFrequencyList();
        this.getLevelList();
        if (this.isAdd) {
          this.initData(this.data, true);
          return;
        }
        this.$axios({
          url: this.$api.marketing.queryEventDetail,
          data: {
            eventId: this.data.eventId
          },
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          let _data = Object.assign({}, this.data, data);
          this.initData(_data, true);
          if (data.memberRange === 1) this.getMemberList();
        });
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            if (this.formData.memberRange === 1 && !this.memberList.length) {
              this.notice('会员范围：手动指定会员不允许为空');
              return;
            }
            // if (!this.formData.points && this.formData.batchIdList.length === 0) {
            //   this.notice('请选择发放积分或发放优惠券');
            //   return;
            // }
            if (!this.formData.points) {
              this.notice('请输入发放积分数');
              return;
            }
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.marketing.addEvent;
              let data = Object.assign({}, this.formData);
              data.beginTime = this.formatDate(data.time[0]);
              data.endTime = this.formatDate(data.time[1]);
              data.memberIdList = this.memberList.map(item => item.memberId);
              data.operateType = {
                'add': 0,
                'edit': 1
              }[this.flag];
              if (data.isLimitPoint === 1) {
                data.pointsPool = -1;
              }
              if (data.triggerMode === 1) {
                data.frequency = 0;
              }
              delete data.time;
              delete data.isLimitPoint;
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 获取事件类型列表
       */
      getTypeList() {
        if (this.eventTypeList.length > 0) return;
        this.$axios({
          url: this.$api.marketing.queryEventType,
          data: {}
        }).then(data => {
          this.eventTypeList = data || [];
        });
      },
      /**
       * 获取事件频率列表
       */
      getFrequencyList() {
        if (this.frequencyList.length > 0) return;
        this.$axios({
          url: this.$api.marketing.queryEventFrequency,
          data: {}
        }).then(data => {
          this.frequencyList = data || [];
        });
      },
      /**
       * 获取等级枚举
       */
      getLevelList() {
        if (this.levelList.length > 0) return;
        this.$axios({
          url: this.$api.member.queryLevelList,
          data: {}
        }).then(data => {
          this.levelList = data || [];
        });
      },
      /**
       * 获取会员信息
       */
      getMemberList() {
        this.memberList = [];
        this.$axios({
          url: this.$api.marketing.queryEventMemberList,
          data: {
            eventId: this.data.eventId
          }
        }).then(data => {
          this.memberList = data || [];
        });
      },
      /**
       * 监听事件类型变化
       */
      changeType(val) {
        if (val === 0 || val === 1 || val === 3 || val === 4 || val === 5) {
          this.formData.frequency = 0;
          this.formData.memberRange = 0;
          this.formData.employeeExclusive = 0;
          this.formData.memberLevelList = this.levelList.map(item => item.levelId);
          this.memberList = [];
          if (val === 3 || val === 4) {
            this.formData.employeeExclusive = 1;
          }
        }
      },
      /**
       * 监听事件有效期变化
       */
      changeTime(time) {
        if (time.length > 1 && time[0] && time[1]) {
          if (this.formatDate(this.formData.time[1]).slice(-8) === '00:00:00') {
            this.formData.time[1] = this.formatDate(this.formData.time[1], 'yyyy-MM-dd') + ' 23:59:59';
          }
        }
        this.$refs['formDataTime'].internalValue = this.formData.time;
      },
      /**
       * 监听发分总数限制变化
       */
      changeLimit(val) {
        if (val === '1') {
          this.formData.pointsPool = -1;
        } else if (val === '2') {
          this.formData.pointsPool = 0;
        }
      },
      /**
       * 监听选择商户变化
       */
      // merchantChange() {
      //   this.storeList = [];
      //   this.formData.storeCode = '';
      //   if (this.formData.merchantCode) {
      //     this.getStoreList();
      //   }
      // },
      /**
       * 获取商户
       */
      // initMerchantList() {
      //   queryMerchantAll(this).then((data) => {
      //     this.merchantList = data || [];
      //   });
      // },
      // 获取门店列表
      // getStoreList(query) {
      //   let params = {
      //     pageNum: 1,
      //     pageSize: 200,
      //     merchantCode: this.formData.merchantCode,
      //   };
      //   if (query) {
      //     params.storeName = query;
      //   }
      //   // queryStoreList(this, params).then((data) => {
      //   //   this.storeList = data.list || [];
      //   // });
      // },
      /**
       * 表格勾选项变化监听
       */
      changeSelection(selection) {
        this.selectMember = selection;
      },
      /**
       * 监听会员范围变化
       */
      changeMemberRange(val) {
        if (val === 0) {
          this.memberList = [];
        } else if (val === 1) {
          this.formData.memberLevelList = [];
        }
      },
      /**
       * 增加券批次ID表单元素
       */
      addCoupon() {
        this.formData.batchIdList.push('');
      },
      /**
       * 移除券批次ID表单元素
       */
      removeCoupon(i) {
        this.formData.batchIdList.splice(i, 1);
      },
      /**
       * 获取会员信息
       */
      handleGetMember(member) {
        this.memberList.every((v) => v.memberId !== member.memberId) &&
          this.memberList.push(member);
      },
      /**
       * 添加会员弹窗
       */
      addMember() {
        this.showAddMember = true;
      },
      /**
       * 删除会员
       */
      removeMember() {
        if (!this.selectMember.length) return;

        const selectMemberId = this.selectMember.map((v) => v.memberId);
        this.$confirm('确认删除选中的会员？').then(() => {
          this.memberList = this.memberList.filter(
            (v) => !selectMemberId.includes(v.memberId)
          );
        });
      },
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>