/**
 * 营销活动管理-营销事件管理
 * luxinwen
 * 2023-04-11
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_MARKETING_EVENT_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.eventId" placeholder="请输入事件ID" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.eventName" placeholder="请输入事件名称" clearable />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.eventType" placeholder="请选择事件类型" clearable>
              <Option v-for="(item, index) in eventTypeList" :key="'eventType' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.status" placeholder="请选择状态" clearable>
              <Option v-for="(item, index) in statusList" :key="'status' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content" v-grant="'OSP_MARKETING_EVENT_SAVE'">
        <Button type="primary" @click="addData">新增事件</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="time">
            <span>{{ params.row.beginTime }} 至 {{ params.row.endTime }}</span>
          </template>
          <template slot-scope="params" slot="status">
            <span>{{ (statusList.find(item => item.code === params.row.status) || {}).desc }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_MARKETING_EVENT_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-show="params.row.status === 0" v-grant="'OSP_MARKETING_EVENT_UPDATE'">编辑</Button>
            <Button @click="triggerData(params.row)" v-show="params.row.status === 0" v-grant="'OSP_MARKETING_EVENT_ENABLE'">启用</Button>
            <Button @click="cancelData(params.row)" v-show="params.row.status === 2 || params.row.status === 3 || params.row.status === 4" v-grant="'OSP_MARKETING_EVENT_CANCEL'">作废</Button>
            <Button @click="auditData(params.row)" v-show="params.row.status === 1" v-grant="'OSP_MARKETING_EVENT_ADUIT'">审核</Button>
            <Button @click="logData(params.row)" v-grant="'OSP_MARKETING_EVENT_LOG'">日志</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <Modal title="审核" v-model="auditModal.display" width="700">
      <Form ref="auditForm" label-position="right" :label-width="130" style="margin-top: 20px" :model="auditModal" :rules="auditRule">
        <FormItem label="确认结果" prop="status">
          <RadioGroup v-model="auditModal.status">
            <Radio :label="0">通过</Radio>
            <Radio :label="1">驳回</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="请输入驳回原因" prop="refuseReason" v-if="auditModal.status === 1">
          <Input v-model="auditModal.refuseReason" type="textarea" maxlength="100" show-word-limit></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" size="large" @click="auditModal.display = false">取消</Button>
        <Button type="primary" size="large" :loading="auditModal.loading" @click="submitAudit">确定</Button>
      </div>
    </Modal>

    <Modal title="操作日志" v-model="logModal.display" width="800" footer-hide>
      <Table :columns="logColumns" :data="logModal.list" :loading="logModal.loading" border></Table>
    </Modal>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';
  import { EVENT_MEMBER_RANGE } from '@/util/enum';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          eventId: '',  // 事件ID
          eventName: '',  // 事件名称
          eventType: null,  // 事件类型，0:会员注册;1:实名认证;2:自定义事件
          status: null, // 状态，0:待启用;1:待审核;2:待生效;3:生效中;4:已过期;5:已作废;6:审核驳回
          triggerMode: 0  // 类型0-自动 1-手动
        },
        tableColumns: [
          {
            title: '事件ID',
            key: 'eventId'
          },
          {
            title: '事件类型',
            key: 'eventTypeValue'
          },
          {
            title: '事件名称',
            key: 'eventName'
          },
          {
            title: '事件频率',
            key: 'frequencyValue'
          },
          {
            title: '有效时间',
            slot: 'time'
          },
          {
            title: '会员范围',
            render: (h, params) => {
              return h('span', EVENT_MEMBER_RANGE[params.row.memberRange]);
            }
          },
          {
            title: '状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        eventTypeList: [],
        statusList: [],
        // frequencyList: [],
        auditModal: {
          display: false,
          loading: false,
          eventId: '',
          status: null,
          refuseReason: ''
        },
        auditRule: {
          status: [
            {
              type: 'number',
              required: true,
              message: '请选择确认结果',
              trigger: 'change'
            }
          ],
          refuseReason: [
            {
              required: true,
              message: '请输入驳回原因',
              trigger: 'blur'
            }
          ]
        },
        logModal: {
          display: false,
          loading: false,
          list: []
        },
        logColumns: [
          {
            title: '时间',
            key: 'createTime'
          },
          {
            title: '操作人',
            key: 'createdBy'
          },
          {
            title: '业务类型',
            key: 'status'
          },
          {
            title: '备注',
            key: 'opinion'
          }
        ]
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_MARKETING_EVENT_VIEW, this.$grant.OSP_MARKETING_EVENT_UPDATE, this.$grant.OSP_MARKETING_EVENT_ENABLE, this.$grant.OSP_MARKETING_EVENT_CANCEL, this.$grant.OSP_MARKETING_EVENT_ADUIT, this.$grant.OSP_MARKETING_EVENT_LOG];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
      this.getTypeList();
      this.getStatusList();
      // this.getFrequencyList();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.marketing.queryEventList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 获取事件类型列表
       */
      getTypeList() {
        this.$axios({
          url: this.$api.marketing.queryEventType,
          data: {}
        }).then(data => {
          this.eventTypeList = data || [];
        });
      },
      /**
       * 获取事件状态列表
       */
      getStatusList() {
        this.$axios({
          url: this.$api.marketing.queryEventStatus,
          data: {}
        }).then(data => {
          this.statusList = data || [];
        });
      },
      /**
       * 获取事件频率列表
       */
      // getFrequencyList() {
      //   this.$axios({
      //     url: this.$api.marketing.queryEventFrequency,
      //     data: {}
      //   }).then(data => {
      //     this.frequencyList = data || [];
      //   });
      // },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 执行事件
       */
      triggerData(row) {
        this.$confirm('选中事件将被执行，请确认是否继续，确定后需进行审核').then(() => {
          this.$axios({
            url: this.$api.marketing.changeEventStatus,
            data: {
              eventId: row.eventId,
              type: 0,
              status: 0
            }
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '启用成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 作废事件
       */
      cancelData(row) {
        this.$confirm('选中事件将被作废，请确认是否继续').then(() => {
          this.$axios({
            url: this.$api.marketing.changeEventStatus,
            data: {
              eventId: row.eventId,
              type: 0,
              status: 1
            }
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '作废成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 审核
       */
      auditData(row) {
        this.auditModal.eventId = row.eventId;
        this.auditModal.status = null;
        this.auditModal.refuseReason = '';
        this.auditModal.display = true;
      },
      /**
       * 审核确认
       */
      submitAudit() {
        this.$refs.auditForm.validate(valid => {
          if (valid) {
            this.$axios({
              url: this.$api.marketing.changeEventStatus,
              data: {
                eventId: this.auditModal.eventId,
                type: 1,
                status: this.auditModal.status,
                refuseReason: this.auditModal.refuseReason
              },
              beforeSend: () => {
                this.auditModal.loading = true;
              },
              complete: () => {
                this.auditModal.loading = false;
              }
            }).then(data => {
              this.notice({
                type: 'success',
                desc: '操作成功'
              });
              this.auditModal.display = false;
              this.getData();
            });
          }
        });
      },
      /**
       * 日志查看
       */
      logData(row) {
        this.$axios({
          url: this.$api.marketing.queryCommonLogList,
          data: {
            unionCode: row.eventId,
            type: 'PROMOTION_OPERATE_LOG'
          },
          posttype: true,
          beforeSend: () => {
            this.logModal.display = true;
            this.logModal.loading = true;
          },
          complete: () => {
            this.logModal.loading = false;
          }
        }).then(data => {
          this.logModal.list = data || [];
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>